import React, { useState } from 'react';
import axios from 'axios';
import './Page4.css';

function Page4() {
  const [inputText, setInputText] = useState('');
  const [selectedDevice, setSelectedDevice] = useState('Downstairs speaker');
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const castToDevice = async () => {
    if (!inputText.trim()) {
      alert('Please enter text to cast.');
      return;
    }

    setIsLoading(true);
    setResponseMessage('');

    try {
      const response = await axios.post(
        'https://kidr78home.tplinkdns.com/api/casttext',
        {
          device: selectedDevice,
          text: inputText,
        },
        {
          headers: {
            Authorization: 'kSuIlUmNaTiIeO5',
          },
        }
      );
      setResponseMessage(response.data.message || 'Text successfully sent!');
    } catch (error) {
      console.error('Error casting text:', error);
      setResponseMessage('Failed to cast text. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="page4-container">
      <div className="text-entry">
        <label htmlFor="inputText">Enter Text to Cast:</label>
        <textarea
          id="inputText"
          rows="4"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Type your message here..."
        />
      </div>

      <div className="device-selector">
        <label htmlFor="deviceSelect">Choose a device:</label>
        <select
          id="deviceSelect"
          value={selectedDevice}
          onChange={(e) => setSelectedDevice(e.target.value)}
        >
          <option value="Downstairs speaker">Downstairs speaker</option>
          <option value="bedroom speaker">Bedroom speaker</option>
          <option value="Valery Studio">Valery Studio</option>
        </select>
      </div>

      <button
        onClick={castToDevice}
        className={isLoading ? 'blinking' : ''}
        disabled={isLoading}
      >
        {isLoading ? 'Casting...' : 'Cast to Device'}
      </button>

      {responseMessage && (
        <div className="response-message">
          {responseMessage}
        </div>
      )}
    </div>
  );
}

export default Page4;

