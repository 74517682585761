import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import Chart from 'chart.js/auto';
import './Page2.css';

function Page2() {
  const [data, setData] = useState({});
  const [currentTemps, setCurrentTemps] = useState({});
  const [bedroomTemp, setBedroomTemp] =  useState({});
  const [studioTemp, setStudioTemp] =  useState({});
  const [outdoorTemp, setOutdoorTemp] =  useState({});
  const [tempStatsData, setTempStatsData] = useState({});
  const [noise, setNoise] = useState({});
  const [co2, setCo2] = useState({});
  const [humidity, setHumidity] = useState({});
  const [pressure, setPressure] = useState({});
  const [numberItems, setNumberItems] = useState(150);
  const [selectedDevice, setSelectedDevice] = useState("Downstairs speaker");
  const [isLoading, setIsLoading] = useState(false);

  const [zIndex, setZIndex] = useState({ div1: 3, div2: 2, div3: 1 });

  const bringToFront = (id) => {
    const updatedZIndex = { ...zIndex };
    let maxZIndex = 0;

    for (const key in updatedZIndex) {
      if (updatedZIndex[key] > maxZIndex) {
        maxZIndex = updatedZIndex[key];
      }
    }

    if (updatedZIndex[id] === maxZIndex) {
      return;
    }

    updatedZIndex[id] = maxZIndex + 1;
    setZIndex(updatedZIndex);
  };

  useEffect(() => {
    const fetchData = async (url, setter) => {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: 'kSuIlUmNaTiIeO5',
          },
          params: {
            number_of_items: numberItems,
          },
        });

        setter(response.data);
      } catch (error) {
        console.error(`Error fetching data: ${error}`);
      }
    };

    fetchData('https://kidr78home.tplinkdns.com/api/temps', setData);
    fetchData('https://kidr78home.tplinkdns.com/api/temps_stats', setTempStatsData);
    fetchData('https://kidr78home.tplinkdns.com/api/noise', setNoise);
    fetchData('https://kidr78home.tplinkdns.com/api/co2', setCo2);
    fetchData('https://kidr78home.tplinkdns.com/api/humidity', setHumidity);
    fetchData('https://kidr78home.tplinkdns.com/api/pressure', setPressure);
  }, [numberItems]);

  const castToDevice = () => {
  const apiUrl = `https://kidr78home.tplinkdns.com/api/casttemps?device_name=${encodeURIComponent(selectedDevice)}`;
  setIsLoading(true);

  fetch(apiUrl, {
    method: "GET",
    headers: {
      Authorization: "kSuIlUmNaTiIeO5",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to call the API");
      }
      return response.json();
    })
    .then((data) => {
      console.log("API Response:", data);
    })
    .catch((error) => {
      console.error("Error calling API:", error);
    })
    .finally(() => {
        setIsLoading(false); // Stop blinking after the promise resolves
      });
};

  useEffect(() => {
    const entr = Object.entries(data);
    setCurrentTemps(entr[entr.length - 1]);
    console.log(data)
  }, [data]);

  useEffect(() => {
    if (currentTemps && currentTemps.length > 1 && typeof currentTemps[1] === 'object') {
      if ('Bedroom' in currentTemps[1]) {
        setBedroomTemp(currentTemps[1].Bedroom);
      }
      if ('Outdoor' in currentTemps[1]) {
        setOutdoorTemp(currentTemps[1].Outdoor);
      }
      if ('Studio' in currentTemps[1]) {
        setStudioTemp(currentTemps[1].Studio);
      }
    }
  }, [currentTemps]);

  if (
    Object.keys(data).length === 0 ||
    Object.keys(tempStatsData).length === 0 ||
    Object.keys(noise).length === 0 ||
    Object.keys(co2).length === 0 ||
    Object.keys(humidity).length === 0 ||
    Object.keys(pressure).length === 0
  ) {
    return <div>Loading...</div>;
  }

  const predefinedColors = ['#FF5733', '#33FF57', '#5733FF'];
  const labels = Object.keys(data);
  const datasets = Object.entries(data[labels[0]]).map(([place, temp], index) => ({
    label: place,
    data: labels.map((label) => data[label][place]),
    fill: false,
    borderColor: predefinedColors[index],
    pointBackgroundColor: predefinedColors[index],
  }));

  const chartData = {
    labels,
    datasets,
  };
  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Temperature',
        },
      },
    },
  };

  const predefinedColorsTempStats = ['#FF5733', '#33FF57', '#5733FF'];
  const labelsTempStats = Object.keys(tempStatsData);
  const datasetsTempStats = Object.entries(tempStatsData[labelsTempStats[0]]).map(([place, tempStatsLevel], index) => ({
    label: place,
    data: labelsTempStats.map((label) => tempStatsData[label][place]),
    fill: false,
    borderColor: predefinedColorsTempStats[index],
    pointBackgroundColor: predefinedColorsTempStats[index],
  }));

  const chartTempStatsData = {
    labels: labelsTempStats,
    datasets: datasetsTempStats,
  };
  const optionsTempStats = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'TempStatsData',
        },
      },
    },
  };

  const labelsNoise = Object.keys(noise);
  const datasetsNoise = Object.entries(noise[labelsNoise[0]]).map(([place, noiseLevel]) => ({
    label: place,
    data: labelsNoise.map((label) => noise[label][place]),
    fill: false,
    borderColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
  }));

  const chartDataNoise = {
    labels: labelsNoise,
    datasets: datasetsNoise,
  };
  const optionsNoise = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Noise',
        },
      },
    },
  };

  const labelsCO2 = Object.keys(co2);
  const datasetsCO2 = Object.entries(co2[labelsCO2[0]]).map(([place, co2Level]) => ({
    label: place,
    data: labelsCO2.map((label) => co2[label][place]),
    fill: false,
    borderColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
  }));

  const chartDataCO2 = {
    labels: labelsCO2,
    datasets: datasetsCO2,
  };
  const optionsCO2 = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'CO2',
        },
      },
    },
  };

  const labelsHumidity = Object.keys(humidity);
  const datasetsHumidity = Object.entries(humidity[labelsHumidity[0]]).map(([place, humidityLevel]) => ({
    label: place,
    data: labelsHumidity.map((label) => humidity[label][place]),
    fill: false,
    borderColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
  }));

  const chartDataHumidity = {
    labels: labelsHumidity,
    datasets: datasetsHumidity,
  };
  const optionsHumidity = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Humidity',
        },
      },
    },
  };

  const labelsPressure = Object.keys(pressure);
  const datasetsPressure = Object.entries(pressure[labelsPressure[0]]).map(([place, pressureLevel]) => ({
    label: place,
    data: labelsPressure.map((label) => pressure[label][place]),
    fill: false,
    borderColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
  }));

  const chartDataPressure = {
    labels: labelsPressure,
    datasets: datasetsPressure,
  };
  const optionsPressure = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Pressure',
        },
      },
    },
  };

  return (
    <>
      <div className="device_selector">
        <label htmlFor="deviceSelect">Choose a device:</label>
        <select
          id="deviceSelect"
          value={selectedDevice}
          onChange={(e) => setSelectedDevice(e.target.value)}
        >
          <option value="Downstairs speaker">Downstairs speaker</option>
          <option value="bedroom speaker">Bedroom speaker</option>
          <option value="Valery Studio Bed">Valery Studio</option>
        </select>
        <button
        onClick={castToDevice}
        className={isLoading ? "blinking" : ""}
        disabled={isLoading} // Optional: disable the button while loading
        >
        {isLoading ? "Casting..." : "Cast to Device"}
      </button>
      </div>


      <div
        id="div1"
        className="floating-div"
        style={{ zIndex: zIndex['div1'] }}
        onClick={() => bringToFront('div2')}
      >
        <div>Bedroom: {JSON.stringify(bedroomTemp)}</div>
        <div>Studio: {JSON.stringify(studioTemp)}</div>
        <div>Outdoor: {JSON.stringify(outdoorTemp)}</div>
      </div>
      <div
        id="div2"
        className="floating-div"
        style={{ zIndex: zIndex['div2'] }}
        onClick={() => bringToFront('div3')}
      >
        <div>Bedroom: {JSON.stringify(bedroomTemp)}</div>
        <div>Studio: {JSON.stringify(studioTemp)}</div>
        <div>Outdoor: {JSON.stringify(outdoorTemp)}</div>
      </div>
      <div
        id="div3"
        className="floating-div"
        style={{ zIndex: zIndex['div3'] }}
        onClick={() => bringToFront('div1')}
      >
        <div>Bedroom: {JSON.stringify(bedroomTemp)}</div>
        <div>Studio: {JSON.stringify(studioTemp)}</div>
        <div>Outdoor: {JSON.stringify(outdoorTemp)}</div>
      </div>

      <div className="select_bar">
        <label htmlFor="numberItems">Items:</label>
        <input
          type="range"
          min="50"
          max="10000"
          step="10"
          id="numberItems"
          value={numberItems}
          onChange={(e) => setNumberItems(e.target.value)}
        />
        <span>{numberItems}</span>
      </div>

      {/* Temperature graph */}
      <div className="graph-container">
        <div className="chart-title">Temperature Trends Over Time</div>
        <Line data={chartData} options={options} />
      </div>

      {/* Noise graph */}
      <div className="graph-container">
        <div className="chart-title">Noise Trends Over Time</div>
        <Line data={chartDataNoise} options={optionsNoise} />
      </div>

      {/* CO2 graph */}
      <div className="graph-container">
        <div className="chart-title">CO2 Trends Over Time</div>
        <Line data={chartDataCO2} options={optionsCO2} />
      </div>

      {/* Humidity graph */}
      <div className="graph-container">
        <div className="chart-title">Humidity Trends Over Time</div>
        <Line data={chartDataHumidity} options={optionsHumidity} />
      </div>

      {/* Pressure graph */}
      <div className="graph-container">
        <div className="chart-title">Pressure Trends Over Time</div>
        <Line data={chartDataPressure} options={optionsPressure} />
      </div>
    </>
  );
}

export default Page2;

